import { useState, useContext, useEffect, useRef } from "react"
import { AppState } from "../../routes/Root"
import axios from "axios"
import useGetUserData from "../../hooks/useGetUserData"
import { useLocation,useNavigate } from "react-router-dom"
import Input, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import "./ProfilePage.css"
import { useAuthToken } from "../../zustand/authStore"
import { useQueryClient } from "@tanstack/react-query"

export default function ProfilePage() {
    const query = useQueryClient()
    const navigate = useNavigate()
    const location = useLocation()
    const refElem = useRef()
    const {token} = useAuthToken((state)=>state)
    const {data:getUserData} = useGetUserData(token)
    const context = useContext(AppState)
    const [value, setValue] = useState()
    const [compState, setCompState] = useState({
        timeouted: false,
        email: null,
        saved_wallet_trc: null,
        counting: 0,
        wallet: null
    })
    function timeout() {
        setTimeout(() => {
            setCompState({ ...compState, timeouted: false })
        },
            3000)
    }
    function setProfile() {
        if (value && isValidPhoneNumber(value) || compState.email !== null || compState.saved_wallet_trc !== null) {
            axios.post("https://api.sprite-ps.com/profile", { ...compState, phone_number: value }, {
                headers: {
                    "Authorization": localStorage.getItem("TokenBearer")
                }
            }).then(response => {
                if (response.status === 200) {
                    context.dispatch({ type: "success_popup", payload: "Data was successful changed" })
                }
                query.invalidateQueries(['profile'])
            }).catch((error) => {
                context.dispatch({ type: "error_popup", payload: error.response.data.detail[0].ctx.reason })
            }
            )
        } else {
            context.dispatch({ type: "error_popup", payload: "Enter valid phone number" })
        }
        setCompState({ ...compState, timeouted: true })
        timeout()
    }
    function backStory() {
        navigate("/", { replace: true })
    }
    useEffect(() => {
        if (getUserData !== null) {
            refElem.current.value = getUserData?.saved_wallet_trc
        }

    }, [getUserData])
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])
    return (
        <div className="profile block">
            <div className="profile_title">
                <h1>Profile</h1>
            </div>
            <div className="profile_names-block">
                <div className="name">
                    <p>First Name:</p>
                    <p>{getUserData?.first_name}</p>
                </div>
                <div className="surname">
                    <p>Middle Name:</p>
                    <p>{getUserData?.middle_name}</p>
                </div>
                <div className="second-name">
                    <p>Last Name:</p>
                    <p>{getUserData?.last_name}</p>
                </div>
            </div>
            <div className="profile_contacts styled-block">
                <div className="profile_phone">
                    <p>Phone:</p>
                    <Input
                        className="invoice-input-extend"
                        placeholder={formatPhoneNumberIntl(getUserData?.phone_number)}
                        value={compState.phone_number}
                        onChange={setValue} />
                </div>
                <div className="profile_mail ">
                    <p>Email:</p>
                    <input type="text"
                        onChange={(e) => setCompState({ ...compState, email: e.target.value })}
                        className="invoice-input"
                        placeholder={getUserData?.email}
                    />
                </div>
                <div className="profile_wallet">
                    <p>Wallet*:</p>
                    <div className="iprofile-currency-block">
                        <input className="invoice-input"
                            ref={refElem}
                            onChange={(e) => setCompState({ ...compState, saved_wallet_trc: e.target.value })}
                            type="text" />
                        <span className="withdraw-warning">
                            *USDT (TRC20)
                        </span>
                    </div>

                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
                    {compState.timeouted ?
                        <button className="btn-done" >Please wait</button>
                        : <button className="btn-done" onClick={() => setProfile()}>Accept Change</button>}

                </div>
            </div>
        </div>
    )
}