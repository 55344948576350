import { Link } from "react-router-dom"
import edit from "../../img/edit.svg"
import arrowRight from '../../img/arrowRight.svg'
import './projectPlate.css'

export default function ProjetPlate({ data }) {
    console.log(data)
    return (
        <div className='balance-plate'>
            <div className='balance-plate_leftCol'>
                <div>
                    <span> {data.name}</span>
                    <div className="status-div">
                        <span>Status :</span>
                        {data.status ? <div className="balance-btn small">Active</div> : null}
                    </div>
                </div>
            </div>
            <Link to={`/my_projects/edit`} state={data} className='balance-plate_leftRight'>
                <img src={arrowRight} alt="delete" className="balance-plate-edit-img" />
            </Link>
        </div >
    )
}