import { useLocation,useNavigate } from "react-router-dom"

import { useEffect } from "react"
import "./FailAuth.css"

export default function FailAuth() {
    const navigate = useNavigate()
    const location = useLocation()
    function backStory() {
        navigate("/", { replace: true })
    }
    useEffect(() => {
        window.Telegram.WebApp.BackButton.hide()
    }, [location.pathname])
    return (
        <div className="block">
            <h1>The account doesn't exist or has been suspend</h1>
        </div>
    )
}