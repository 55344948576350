import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState, createContext, useReducer, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import loading from "../img/loading.svg"
import axios from "axios"
import "../components/footerControl/footerControl.css"
import 'react-toastify/dist/ReactToastify.css';
import axiosRetry from "axios-retry";
import {
    QueryClient,
    useQuery
} from '@tanstack/react-query'
import "./Root.css"
import { BASE_URL } from "../static/static";
import { useAuthToken } from "../zustand/authStore";
import ErrorPage from "../pages/ErrorPage/ErrorPage";

const initialState = {
    page: "main_page",
    invoiceDone: 0,
    invose: false,
    applications: false,
    userInfo: null,
    token: null,
    userBalance: null,
    userPhoto: null,
    projects: [],
    lastTransactions: null
}

const queryClient = new QueryClient()

function reducer(state, action) {
    switch (action.type) {
        case "add_last_transactions":
            return { ...state, lastTransactions: action.payload }
        case "add_projects":
            return { ...state, projects: action.payload }
        case "set_user_photo":
            return { ...state, userPhoto: action.payload }
        case "set_token":
            return { ...state, token: action.payload }
        case "add_user_names":
            return { ...state, userInfo: action.payload }
        case "add_user_balance":
            return { ...state, userBalance: action.payload }
        case "switch_page":
            return { ...state, page: action.payload }
        case "open_invose":
            return { ...state, invose: true, invoiceDone: action.payload }
        case "close_invose":
            return { ...state, invose: false, invoiceDone: null }
        case "open_my_applications":
            return { ...state, applications: true }
        case "close_my_applications":
            return { ...state, applications: false }
        case "success_popup":
            toast(action.payload, { autoClose: 3000 })
            return { ...state }
        case "error_popup":
            toast.error(action.payload, { autoClose: 3000 })
            return { ...state }
        default:
            console.warn("Error in app reducer")
    }
}
const fakeUser = { "user": { "id": 5432487039, "first_name": "NaN", "last_name": "NaN", "username": "NaN913", "language_code": "ru", "allows_write_to_pm": true }, "chat_instance": "4313923184418680035", "chat_type": "private", "auth_date": "1731331405", "hash": "85c60210be701b40325096b35a8912b2bba50cd37f4aba3f584626ed5c043900" }
export const AppState = createContext(initialState)

export default function Root() {
    axiosRetry(axios, { retries: 5 });
    const [state, dispatch] = useReducer(reducer, initialState)
    const setToken = useAuthToken((state)=>state.useSetToken)
    const [compState, setCompState] = useState({
        page: "main_page",
        userData: null,
        respons: null,
        token: null
    })
    const location = useLocation()
    const navigate = useNavigate()
    const fakeUserStan = {
        "user": {
            "id": 270047968,
            "first_name": "Konstantin",
            "last_name": "",
            "username": "stansxnn",
            "language_code": "ru",
            "allows_write_to_pm": true
        },
        "chat_instance": "-3137624852284963928",
        "chat_type": "private",
        "auth_date": "1731400601",
        "hash": "722740bb132f5a2ffd17be1dedf957fa1a3e1851327e8a03fc9b85c272d54063"
    }
    console.log(window.Telegram.WebApp)
    console.log('query data user = ', window.Telegram.WebApp.initData)
    const realUser = window.Telegram.WebApp.initDataUnsafe
    const initialize = async () => {
        console.log('try to initialize')
          const response = await axios.post(
            `${BASE_URL}/app_auth`
          , realUser);
          
          let token = response.headers['authorization'];
          setToken(token)
          dispatch({ type: "set_token", payload: response.headers['authorization']})
          setCompState({ ...compState, token: response.headers['authorization'] })
          localStorage.setItem("TokenBearer", token)
          
          return response
        }

        const {data : auth, 
            isError,
            isLoading
          } = useQuery({
            queryKey:['authQuery'],
            queryFn:initialize,
            retry:3 
          })

    useEffect(() => {
        console.log("~~~~RenderedWebApp~~~~")
        if (window.Telegram && window.Telegram.WebApp) {
            console.log('data unsafe' , window.Telegram.WebApp.initDataUnsafe)
            console.log('query data', window.Telegram.WebApp.initData)
            console.log("sending token")
            window.Telegram.WebApp.ready();
            window.Telegram.WebApp.expand();
            window.Telegram.WebApp.setHeaderColor("#141723")
            window.Telegram.WebApp.setBackgroundColor("#141723")
            window.Telegram.WebApp.BackButton.onClick(()=> navigate(-1))
            window.Telegram.WebApp.disableVerticalSwipes()
            window.Telegram.WebApp.enableClosingConfirmation()
            console.log('tg', window.Telegram.WebApp)
        }
    }, [])

    useEffect(() => {
        console.log('LOCATION', location)
        if (location.pathname !== '/') {
            window.Telegram.WebApp.BackButton.show()
        } else {
            window.Telegram.WebApp.BackButton.hide()
        }
    }, [location.pathname])
    console.log('authQuery', auth)
  console.log('Authtoken', localStorage.getItem('TokenBearer'))
  if(isLoading){
    <div className="block spinner-load">
                <div className="spinner-wrapper">
                    <span className="spinner-title">Loading</span>
                    <img src={loading} alt="loading" className="spinner" />
                </div>

            </div>
  }
  if(isError){
    return <ErrorPage/>
  }
    
        return (
            <AppState.Provider value={{ data: state, dispatch: dispatch }}>
                <ToastContainer
                    position="top-left"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                    theme="dark"
                    limit={1}
                />
                <div className="mainFrame">
                    <Outlet />
                </div >
            </AppState.Provider >
        );


}