import React, { useContext, useEffect, useState } from "react"
import { AppState } from "../../routes/Root"
import ActivetyPlate from "../activetyPlate/activetyPlate"
import { Link } from "react-router-dom"
import "./LastActivity.css"
import { useAuthToken } from "../../zustand/authStore";
import { useSortTransStore } from "../../zustand/sortTransStore";
import { useGetLastTransactions } from "../../hooks/useGetLastTransactions";
import { useInView } from "react-intersection-observer"
import { useGetInfiniteTransactions } from "../../hooks/useInfiniteTransactions"
import Loading from "../loader/Loading"
import NoTransactions from "../loader/NoTransactions"
import UseGetLoadMore from "../../hooks/useGetLoadMore"
import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
    import axios from 'axios';
import { BASE_URL } from "../../static/static"
    

export default function LastActivety() {
    const context = useContext(AppState)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const {active_sortTrans, setSortTrans} = useSortTransStore((state)=>state)
    const [selectedFilter, setSelectedFilter] = useState(active_sortTrans==true ? 'Filled':
        active_sortTrans==false ? 'Unfilled' : 'All'
     )

    const {token} = useAuthToken((state)=>state)
    const [arr, setArr] = useState([])


    
    
    const fetchData = async ({pageParam}) => {
        console.log('Last transactions body = ', {page:pageParam})
        const response = await axios.get(`${BASE_URL}/last_transactions?page=${pageParam}${active_sortTrans !== null ? `&status=${active_sortTrans}` : ``}`,{
            headers: {
                "Authorization": token
            }
        })
        return response.data
    }
    
     const {data:last_transactions,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        fetchNextPage
     } = useInfiniteQuery({
            queryKey:['transload', active_sortTrans],
            queryFn:fetchData,
            initialPageParam:1,
            getNextPageParam:(lastPage, pages)=>lastPage.next_page_num,
            enabled:!!token
        }
        )


    
    console.log('loadmore hook',  {last_transactions,
        nextPAge:hasNextPage,
        isFetching,
        isFetchingNextPage})
    // const {data:last_transactions, isLoading} = useGetLastTransactions(token, active_sortTrans, page, [], 'All')
    // const {data:last_transactions, hasNextPage, isFetchingNextPage, fetchNextPage} = useGetInfiniteTransactions({token, status:active_sortTrans, page:1})
    const { ref, inView } = useInView({
          // Сработает только один раз
        onChange: (inView) => {
          if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();  // Загружаем следующую страницу
          }
        },
      });
    
    //   console.log({hasNextPage, isFetchingNextPage, fetchNextPage})
    const sort_transactions = [
        'Filled',
        'Unfilled',
        'All'
    ]

    const handleChooseFilter = (item) => {
        if(item==="Filled"){
            setSortTrans(true)
            setSelectedFilter(item)
        } else if(item === "Unfilled"){
            setSortTrans(false)
            setSelectedFilter(item)
        } else {
            setSortTrans(null)
            setSelectedFilter('All')
        }
    }

    return (
        <>
            <div className="mainFrame_lastActiviti block">
                <div className="mainFrame_lastActiviti-header">
                    <Link to={"/last_transactions"}>
                        Last Transactions
                    </Link>
                    <div className="mainFrame_lastActiviti-header-sort">
                    {
                        sort_transactions.map((item,index) =>
                            <a 
                        key={index}
                        className={selectedFilter === item ? 'active' : ''}
                        onClick={()=>handleChooseFilter(item)}  
                        >
                            {item}
                        </a>)
                    }
                    </div>
                </div>
                <div className="mainFrame_lastActiviti-body">
                {/* {
                    last_transactions &&
                    last_transactions.data.length > 0 ?
                    last_transactions.data.map((element, index) => {
                        return <ActivetyPlate key={index} data={element} />
                    })
                    : 
                    isLoading ?
                        <Loading/>
                    :
                        <NoTransactions/>
                } */}
                {/* {
                    last_transactions &&
                    last_transactions.next_page &&
                    <button 
                        onClick={()=>setPage(prev=>++prev)}
                        className="btn-repeat">
                        Load more
                    </button>
                } */}
                </div>
                
                    {
                        last_transactions &&
                        <div className="mainFrame_lastActiviti-body">
                        {last_transactions.pages.map((page,index) =>(
                            <React.Fragment key={index}>
                                {
                                    page.data.map((element)=>(
                                        <ActivetyPlate key={element.order_id} data={element}/>
                                    ))
                                }
                            </React.Fragment>
                        ))
                    }
                        </div>
                        
                    
                    }
                {
                isFetchingNextPage ? <Loading/>
                :
                hasNextPage &&
                <div  style={{height:'2px', width:'2px'}} ref={ref} />
                }
                
            </div>
            
        </>
    )
}