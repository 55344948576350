import { useContext } from "react"
import { AppState } from "../../routes/Root"
import { useState } from "react"
import "./scrollDownListOnlyCurrency.css"


export default function ScrollDownListOnlyCurrency({ array, selecting, closingComponent }) {
    const context = useContext(AppState)
    const [compState, setCompState] = useState({
        dropDownActive: true,
        selectedCurrency: "",
        selectFilter: "ALL",
        // filteredArray: cards
    })
    return (
        <div className="scroll-down-bg" >
            <div className="scroll-down-close">
                <span onClick={(e) => closingComponent(e)}>+</span>
            </div>
            <div className="scroll-down-filter-list">
            </div>
            <ul className="scroll-down-list block">
                {array.map((element, index) => {
                    return <li key={index} onClick={() => { selecting(element) }}>{element[0]+" ("+element[1]+")"}</li>
                })}
            </ul>
        </div>
    )
}