import { useContext, useState } from "react"
import { AppState } from "../../routes/Root"
import "./PaymentInvoce.css"
import { useSortTransStore } from "../../zustand/sortTransStore"

export default function PaymentInvoce() {
    const context = useContext(AppState)
    const {active_sortTrans} = useSortTransStore((state) => state)

    const [compState, setCompState] = useState({
        invoiceLink: false
    })
    function dataFormating(value) {
        let formatedArray = value.split("-")
        let months = ["January ", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        return (formatedArray[0]+"."+formatedArray[1] +"." + formatedArray[2])
    }
    function closeModal(e) {
        e.stopPropagation()
        context.dispatch({ type: "close_invose" })
    }
    function shareButtonFunc(event, data, value) {
        event.preventDefault()
        navigator.clipboard.writeText(data)
        context.dispatch({ type: "success_popup", payload: value })
    }
    console.log('Active sortTrans =', active_sortTrans)
    return (
        <div className="payment-invoce-popup" onClick={(e) => closeModal(e)}>
            <div className="payment-done block styled-block" onClick={(e) => e.stopPropagation()}>
                <p className="payment-done-title">
                    { context.data.invoiceDone.status ? 'Payment Success!' : 
                    'Payment Pending!' 
                    }</p>
                {/* <p className="payment-done-title-info">Your payment for {context.data.invoiceDone.invoice} has<br /> been successfully done</p> */}
                <span className="price">
                    <p>Total Payment {context.data.invoiceDone.invoice !== null ? <button className="balance-btn small">invoice</button> : <button className="balance-btn small">order</button>}<span></span></p>
                    <p>{context.data.invoiceDone.payment_method.currency ? context.data.invoiceDone.payment_method.currency : '----'} {context.data.invoiceDone.payment_method.amount ? context.data.invoiceDone.payment_method.amount : '----'}</p>
                </span>
                <div className="payment-done-info">
                    <div className="payment-done-info-row"><span>Title</span><span className="wrap-words">{context.data.invoiceDone.title ? context.data.invoiceDone.title : '-----'}</span></div>
                    <div className="payment-done-info-row"><span>Order id</span><span>{context.data.invoiceDone.order_id ? context.data.invoiceDone.order_id : '-----'}</span></div>
                    {context.data.invoiceDone.invoice !== null ?
                        <div className="payment-done-info-row">
                            <span>Link</span>
                            {!compState.invoiceLink  ? <span><button className="balance-btn small" onClick={(e) => setCompState({ ...compState, invoiceLink: true })}>show</button></span> : null}
                            {compState.invoiceLink ? <span onClick={(e) => { shareButtonFunc(e, context.data.invoiceDone.invoice, "Link copied") }}>{context.data.invoiceDone.invoice}</span> : null}
                        </div>
                        : null}
                    <div className="payment-done-info-row"><span>Email</span><span className="wrap-words">{context.data.invoiceDone.buyer_email ? context.data.invoiceDone.buyer_email : '-----'}</span></div>
                    {context.data.invoiceDone.invoic === null && context.data.invoiceDone.user_tag === null ?
                        <div className="payment-done-info-row"><span>Tag</span><span className="wrap-words">{context.data.invoiceDone.user_tag}</span></div>
                        : null
                    }
                    <div className="payment-done-info-row">
                        <span>Date</span>
                        <span className="wrap-words">{context.data.invoiceDone.filled_date ?  dataFormating(context.data.invoiceDone.filled_date.split(" ")[0]) : '-----'}</span>
                    </div>
                    <div className="payment-done-info-row"><span>Time</span><span>{context.data.invoiceDone.filled_date ? context.data.invoiceDone.filled_date.split(" ")[1] : '-----'}</span></div>
                </div>
                <div className="payment-done-btn">
                    <button className="btn-done" onClick={(e) => closeModal(e)} >Close</button>
                </div>
            </div>
        </div>
    )
}