
import { useQuery } from "@tanstack/react-query";
import { getCurrencies } from "../api/Api";

export default function useGetCurrencies(token) {
    return useQuery({
        queryKey:['currencies'],
        queryFn:()=>getCurrencies(token),
        enabled:!!token
    })
}