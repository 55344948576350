import { useContext, useEffect, useState } from "react"
import netflix from "../../img/netflix.svg"
import thron from "../../img/crypto/tron.png"
import eth from "../../img/crypto/ethereum.png"
import paypal from "../../img/paypal.png"
import usdt from "../../img/crypto/tether.png"
import PaymentInvoce from "../paymentDone/PaymentInvoce"
import { AppState } from "../../routes/Root"
import "../activetyPlate/activetyPlate.css"

export default function ActivetyPlateForInvoices({ data }) {

    const [compState, setCompState] = useState(null)
    const context = useContext(AppState)
    const price = Math.ceil(Math.random() * 100)
    function imgSwitcher() {
        switch (data.payment_system) {
            case "Tron":
                return thron
            case "Ethereum":
                return eth
            case "Tether":
                return usdt
            case "PayPal":
                return paypal
        }
    }
    
    useEffect(() => {
        if (data !== undefined) {
            setCompState(data)
        }
    }, [data])
    return (
        <>
        
            <div className="mainFraim_lastActiviti-body-element styled-block-small" onClick={() => context.dispatch({ type: "open_invose", payload: data})}>
                <div className="mainFraim_lastActiviti-body-leftcol">
                    <img src={imgSwitcher()} alt="image" className="image-body-element button-img" />
                    <span>
                        <p>{data.product_name ? data.product_name : 'No Name'}</p>
                        <p>ID:{data.invoice_id}</p>
                    </span>
                </div>
                <div className="activity-subs-price">
                    <span>
                        {data.currency} {data.amount}
                    </span>
                </div>
            </div>
        </>
    )
}