import React from 'react';
import '../../pages/ErrorPage/ErrorPage.css'
const Loading = () => {
    return (
        <div className='page' style={{minWidth:'100%', minHeight:'auto'}}>
            <h1 style={{fontSize:'20px'}}>Loading ...</h1>  
        </div>
    );
}

export default Loading;
