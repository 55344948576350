import React from 'react';
import '../../pages/ErrorPage/ErrorPage.css'
const NoTransactions = () => {
    return (
        <div className='page' style={{minWidth:'100%', minHeight:'auto'}}>
            <h1 style={{fontSize:'20px'}}>No Transactions</h1>  
        </div>
    );
}

export default NoTransactions;
