import { useContext, useEffect, useState } from "react"
import netflix from "../../img/netflix.svg"
import thron from "../../img/crypto/tron.png"
import eth from "../../img/crypto/ethereum.png"
import paypal from "../../img/paypal.png"
import usdt from "../../img/crypto/tether.png"
import unchecked from '../../img/unchecked.svg'
import eur from '../../img/eur.svg'
import usd from '../../img/usd.svg'
import PaymentInvoce from "../paymentDone/PaymentInvoce"
import { AppState } from "../../routes/Root"
import "./activetyPlate.css"

export default function ActivetyPlate({ data }) {
    
    const [compState, setCompState] = useState(null)
    const context = useContext(AppState)
    const price = Math.ceil(Math.random() * 100)
    const payedTime =  data.filled_date ? data.filled_date.split(" ")[0] : ''
    function dataFormating (value,time) {
        let formatedArray = value.split("-")
        let months =["Jan ","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        return ( months[formatedArray[1]-1]+" "+formatedArray[0]+", "+formatedArray[2]+" "+time.slice(0,5) )
    }
    useEffect(() => {
        if (data !== undefined) {
            setCompState(data)
        }
    }, [data])
    const changeImage = () => {

        if(data.payment_method.payment_system_name){
            if(data.payment_method.payment_system_name === "Tron"){
                return thron
            } else if (data.payment_method.payment_system_name === "Ethereum"){
                return eth
            } else if (data.payment_method.payment_system_name === "Tether"){
                return usdt
            } else if (data.payment_method.payment_system_name === "paypal"){
                return paypal
            } else {
                return unchecked
            }
        } else if(data.payment_method.currency){
            if(data.payment_method.currency === "TRX"){
                return thron
            } else if(data.payment_method.currency === "USDT"){
                return usdt
            } else if (data.payment_method.currency === "ETH"){
                return eth
            } else if (data.payment_method.currency === "USD"){
                return usd
            } else if(data.payment_method.currency === "EUR"){
                return eur
            } else {
                return unchecked
            }
        }
    }
    return (
        <>
            <div className="mainFraim_lastActiviti-body-element styled-block-small" onClick={() => context.dispatch({ type: "open_invose", payload: data})}>
                <div className="mainFraim_lastActiviti-body-leftcol">
                    <img src={changeImage()} alt="image" className="image-body-element button-img" />
                    <span >
                        <p>{data.title ? data.title : 'No Name'}</p>
                        <p style={{maxWidth:'160px'}}>{ data.filled_date ? `${dataFormating(payedTime,data.filled_date.split(" ")[1])} |` :''}  {data.service_name}</p>
                    </span>
                </div>
                <div className="activity-subs-price">
                    <span >
                        {data.payment_method.currency} {data.payment_method.amount}
                    </span>
                </div>
            </div>
        </>
    )
}