import ProjetPlate from '../../components/projectPlate/projectPlate'
import useGetUserData from '../../hooks/useGetUserData'
import axios from 'axios'
import { AppState } from '../../routes/Root'
import axiosRetry from 'axios-retry'
import { useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './ProjectsPage.css'
import useGetServices from '../../hooks/useGetServices'
import { useAuthToken } from '../../zustand/authStore'
import Loading from '../../components/loader/Loading'

export default function ProjectsPage() {
    const context = useContext(AppState)
    const navigate = useNavigate()
    const location = useLocation()
    const [compState, setCompState] = useState({ data: [] })
    function backStory() {
        navigate("/", { replace: true })
    }
    const {token} = useAuthToken((state)=>state)
    const {data, isLoading} = useGetServices(token)

    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    //     // if (context.data.token !== null) {
    //     //     axios.get("https://api.sprite-ps.com/services", {
    //     //         headers: {
    //     //             "Authorization": context.data.token
    //     //         }
    //     //     })
    //     //         .then(response => context.dispatch({ type: "add_projects", payload: response.data }))
    //     //         .catch(error => console.log("Error in projects " + error.status))
    //     // }
    // }, [location.pathname])
    console.log(context.projects)
    return (
        <div className='my-projects block'>
            <div className='my-projects_header'>
                <h1>My Projects</h1>
            </div>
            <div className='my-projects_list'>
                {
                isLoading ? 
                <Loading/> :
                data ?
                data.data.length > 0 &&
                    data.data.map((element, index) => {
                        return <ProjetPlate key={index} data={element} />
                    })
                    :
                    <Loading/>
                }
            </div>
        </div>
    )
}