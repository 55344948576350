import { useContext, useState } from "react"
import { AppState } from "../../routes/Root"
import "./PaymentInvoce.css"

export default function PaymentAllInvoices() {
    const context = useContext(AppState)
    const [compState, setCompState] = useState({
        invoiceLink: false
    })
    
    function closeModal(e) {
        e.stopPropagation()
        context.dispatch({ type: "close_invose" })
    }
    function shareButtonFunc(event, data, value) {
        event.preventDefault()
        console.log('sahre', data)
        navigator.clipboard.writeText(data)
        context.dispatch({ type: "success_popup", payload: value })
    }
    console.log(context.data.invoiceDone.user_tag)
    return (
        <div className="payment-invoce-popup" onClick={(e) => closeModal(e)}>
            <div className="payment-done block styled-block" onClick={(e) => e.stopPropagation()}>
                <p className="payment-done-title">Payment Info</p>
                {/* <p className="payment-done-title-info">Your payment for {context.data.invoiceDone.invoice} has<br /> been successfully done</p> */}
                <span className="price">
                    <p>Payment System {context.data.invoiceDone.invoice !== null ? <button className="balance-btn small">{context.data.invoiceDone.payment_system}</button> : <button className="balance-btn small">order</button>}<span></span></p>
                    <p>{context.data.invoiceDone.currency} {context.data.invoiceDone.amount}</p>
                </span>
                <div className="payment-done-info">
                    <div className="payment-done-info-row"><span>Title</span><span className="wrap-words">{context.data.invoiceDone.product_name ? context.data.invoiceDone.product_name : "No Name"}</span></div>
                    <div className="payment-done-info-row"><span>Service</span><span>{context.data.invoiceDone.service_name ? context.data.invoiceDone.service_name : '-----'}</span></div>
                    <div className="payment-done-info-row"><span>Invoice id</span><span>{context.data.invoiceDone.invoice_id}</span></div>
                    {context.data.invoiceDone.invoice !== null ?
                        <div className="payment-done-info-row">
                            <span>Link</span>
                            {!compState.invoiceLink  ? <span><button className="balance-btn small" onClick={(e) => setCompState({ ...compState, invoiceLink: true })}>show</button></span> : null}
                            {compState.invoiceLink ? <span onClick={(e) => { shareButtonFunc(e, context.data.invoiceDone.url, "Link copied") }}>{context.data.invoiceDone.url}</span> : null}
                        </div>
                        : null}
                    
                </div>
                <div className="payment-done-btn">
                    <button className="btn-done" onClick={(e) => closeModal(e)} >Close</button>
                </div>
            </div>
        </div>
    )
}