
import { useQuery } from "@tanstack/react-query";
import { getUserProfile } from "../api/Api";

export default function useGetUserData(token) {
    return useQuery({
        queryKey:['profile'],
        queryFn:()=>getUserProfile(token),
        enabled:!!token
    })
}