import { useState } from "react"
import "./balanceController.css"

export default function BalanceController({ setActive }) {
    const [compState, setCompState] = useState({
        active: "current"
    })

    function selecting(value) {
        setActive(value)
        setCompState({ ...compState, active: value })
    }
    return (
        <div className="balance-controller">
            <div className="balance-controller_leftcol">
                <span className="av-Coins" onClick={() => { selecting("current") }}>
                    Current
                </span>
                <span className={compState.active === "current" ? "activeBalanceController" : null}></span>
            </div>
            <div className="balance-controller_rightcol" onClick={() => { selecting("history") }}>
                <span className="av-portfolio">
                    History
                </span>
                <span className={compState.active === "history" ? "activeBalanceController" : null}></span>
            </div>
        </div>
    )
}