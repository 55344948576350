import wallet from "../../img/wallet.svg"
import plus from "../../img/plus.svg"
import profile from "../../img/profile.svg"
import moar from "../../img/more.svg"
import invoice from "../../img/coins.svg"
import axios from "axios"
import { useLocation } from "react-router-dom"
import { Link, useNavigate } from "react-router-dom"
import LastActivety from "../../components/lastActivety/LastActivity"
import PaymentInvoce from "../../components/paymentDone/PaymentInvoce"
import { useContext, useState } from "react"
import { AppState } from "../../routes/Root"
import axiosRetry from 'axios-retry';
import "./MainPage.css"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { getConsolidatedBalance, getLastTransactions, getUserProfile, getUserProfilePic } from "../../api/Api"
import { useAuthToken } from "../../zustand/authStore"

export default function MainPage() {
    const location = useLocation()
    
    const context = useContext(AppState)
    // console.log('context', context)
    const navigate = useNavigate()
    const [compState, setCompState] = useState({
        profileImg: null,
        balance: null,
        currency: "USD"
    })
    function formatingNumber(value) {
        return new Intl.NumberFormat('en-US').format(value)
    }
    // useEffect(() => {
    //     if (context.data.token !== null) {
    //         axios.get("https://api.sprite-ps.com/profile",
    //             {
    //                 headers: {
    //                     "Authorization": context.data.token
    //                 }
    //             }
    //         ).then(response =>
    //             context.dispatch({ type: "add_user_names", payload: response.data })
    //         ).catch(error =>
    //             console.log(error.status)
    //         )
    //         axios.get("https://api.sprite-ps.com/user_photo",
    //             {
    //                 responseType: "blob",
    //                 headers: {
    //                     "Authorization": context.data.token
    //                 }
    //             }
    //         ).then(response => {
    //             if (response.data.size === 0) {
    //                 context.dispatch({ type: "set_user_photo", payload: null })
    //             } else {
    //                 const data = URL.createObjectURL(response.data)
    //                 context.dispatch({ type: "set_user_photo", payload: data })
    //             }
    //         }).catch(error =>
    //             console.log(error.status)
    //         )
    //     }
    // }, [context.data.token])

    const {token} = useAuthToken((state)=>state)
    
    const consolidatedBalance = useQuery({
        queryKey:['consBal'],
        queryFn:()=>getConsolidatedBalance(token),
        enabled:!!token
    })

    const user = useQuery({
        queryKey:['profile'],
        queryFn:()=>getUserProfile(token),
        enabled:!!token
    })
    const {data:user_photo} = useQuery({
        queryKey:['user_photo'],
        queryFn:()=>getUserProfilePic(token),
        enabled:!!token
    })
    
    
    return (
        <>
            {context.data.invose ?  
                <PaymentInvoce /> : null
            }
            <div className="mainFrame_header block ">
                {user.data ? <h1><span>Welcome,</span><br /><span>{user.data.first_name} {user.data.last_name}</span></h1>
                    : <h1><span>Welcome,</span><br /><span>John Doe</span></h1>}
                {!user_photo ?
                    <Link to={"/profile"} className="mainFrame_header-avatar">
                        <img src={profile} alt="alt profile" />
                    </Link>
                    :
                    <Link to={"/profile"} className="mainFrame_header-avatar">
                        <img src={URL.createObjectURL(user_photo)} alt="alt profile" />
                    </Link>
                }
            </div>
            <div className="mainFrame_balance block styled-block">
                <div className="mainFrame_balance-button-and-money">
                    <button className="balance-btn">Total Balance</button>
                    {consolidatedBalance.data ? <h2>≈ {formatingNumber(consolidatedBalance.data.sum_balances)} {consolidatedBalance.data.target_currency}</h2> :<h2 className="balance-placeholder"></h2> }
                    <hr />
                </div>
                <div className="mainFrame_balance-controls-button">
                    <Link to={"/invoice"} className="utility-btn" state={'ORDER'}>
                        <img src={plus} alt="plus" className="button-img" />
                        <p>Orders</p>
                    </Link>
                    <Link to={"/invoice"} className="utility-btn" state={'INVOICE'}>
                        <img src={invoice} alt="coins" className="button-img" />
                        <p>Invoice</p>
                    </Link>
                    <Link to={"/total_balance"} className="utility-btn ">
                        <img src={wallet} alt="wallet" className="button-img" />
                        <p>Balance</p>
                    </Link>
                    <Link to={"/my_projects"} className="utility-btn">
                        <img src={moar} alt="MOAR" className="button-img" />
                        <p>Projects</p>
                    </Link>
                </div>
            </div>
            <LastActivety />
        </>
    )
}