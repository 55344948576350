
import { useQuery } from "@tanstack/react-query";
import { getWithdrwals } from "../api/Api";

export default function useGetWithdrawals(token) {
    return useQuery({
        queryKey:['history'],
        queryFn:()=>getWithdrwals(token),
        enabled:!!token
    })
}