import coins from "../../img/coins.svg"
import arrowUP from "../../img/arrowUp.svg"
import arrowRight from "../../img/arrowRight.svg"
import { Link } from "react-router-dom"
import paypal from "../../img/paypal.png"
import usdt from "../../img/USDT.png"
import dollar from '../../img/crypto/binance.png'
import bitcoin from "../../img/crypto/tether.png"
import eth from "../../img/crypto/ethereum.png"
import trx from "../../img/crypto/tron.png"
import "./BalancePlate.css"

export default function BalancePlate({ value = "Eth", amount, fee }) {
    const randomNumb = Math.ceil(Math.random() * 1000)
    console.log('Balance plate', fee)
    function switchValue(value) { 
        switch (value) {
            case "eth":
                return (
                    <div>
                        <img src={eth} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>ETH</div>
                        </div>
                    </div>
                )
            case "eur":
                return (
                    <div>
                        <img src={paypal} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>Paypal Euro</div>
                        </div>
                    </div>
                )
            case "usd":
                return (
                    <div>
                        <img src={paypal} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>Paypal USD</div>
                        </div>
                    </div>
                )
            case "usdt":
                return (
                    <div>
                        <img src={usdt} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>USDT</div>
                        </div>
                    </div>)
            case "btc":
                return (
                    <div>
                        <img src={bitcoin} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>BTC</div>
                        </div>
                    </div>)
            case "trx":
                return (
                    <div>
                        <img src={trx} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>TRX</div>
                        </div>
                    </div>)
            case "dollar":
                return (
                    <div>
                        <img src={dollar} alt="ethis" className="img-balance-element" />
                        <div className="plate-information">
                            <div>USD</div>
                        </div>
                    </div>)
            default:
                console.warn("Error in currensy switcher")
        }
    }
    function switchCurrency(value) {
        switch (value) {
            case "eth":
                return "ETH"
            case "usdt":
                return "USDT"
            case "btc":
                return "BTC"
            case "trx":
                return "TRX"
            case "usd":
                return "$"
            case "eur":
                return "€"
            default:
                console.log("Error in currency")
        }
    }
    function switchFee(value) {
        switch (value) {
            case "eth":
                return fee.ethereum_fee + " %"
            case "usdt":
                return fee.tether_fee + " %"
            case "trx":
                return fee.tron_fee + " %"
            case "eur":
                return fee.paypal_fee + " %"
            case "usd":
                return fee.paypal_fee + " %"
            default:
                console.log("Error in Fee")
        }
    }
    return (
        <div>
            <div className="mainFrame_balance-page_item block" >
                <div className="mainFrame_balance-page_item-leftCol">
                    {switchValue(value)}
                    <Link to={`/total_balance/withdraw`} state={{ walletName: switchCurrency(value), balance: amount, currency: value, fee_data: fee }} >
                        <img src={arrowRight} className="arrow-next-page" />
                    </Link>
                </div>
                <hr />
                <div className="mainFrame_balance-page_item-rightCol">
                    <div className="blanace-page_information">
                        <div>
                            Est. APY
                        </div>
                        <span>Balance</span>
                    </div>
                    <div className="blanace-page_currency">
                        <span className="averege_comission"><strong>{switchFee(value)}</strong></span>
                        <span className=""><strong>{amount} {switchCurrency(value)}</strong> </span>
                    </div>
                </div>
            </div>
        </div>
    )
}