import { useLocation, useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AppState } from '../../routes/Root'
import './EditingProjectPage.css'

export default function EditingProjectPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(AppState)
    let passedState = useLocation()

    function backStory() {
        navigate("/my_projects", { replace: true })
    }
    function shareButtonFunc(event, data, value) {
        event.preventDefault()
        navigator.clipboard.writeText(data)
        context.dispatch({ type: "success_popup", payload: value })
    }

    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])
    return (
        <div className='editing-project-page block'>
            <div className='editing-project-page_title-wrapper'>
                <div className='editing-project-page_title'>
                    <h1>{passedState.state.name} </h1>
                </div>
            </div>
            <div className='editing-project-page_content styled-block'>
                <div className='edit-project-page_link'>
                    <p>Link :</p>
                    <a className="edit-project-link" onClick={(e) => shareButtonFunc(e, passedState.state.link, "Link copied")} href={passedState.state.link}>
                        {passedState.state.link}
                    </a>
                </div>
                <div className='edit-project_api'>
                    <p>Api Key :</p>
                    <span onClick={(e) => shareButtonFunc(e, passedState.state.api_key, "Api key copied")} > {passedState.state.api_key}</span>
                </div>
                <div className='edit-project_key'>
                    <p>Secret Key :</p>
                    <span onClick={(e) => shareButtonFunc(e, passedState.state.secret_key, "Secret key copied")} > {passedState.state.secret_key}</span>
                </div>
                <div className='edit-project_token'>
                    <p>Jwt Bearer Token* :</p>
                    <span className="edit-project_token-content" onClick={(e) => shareButtonFunc(e, localStorage.getItem("TokenBearer").split("Bearer ")[1], "Token copied")} >
                        <span>{localStorage.getItem("TokenBearer").split("Bearer ")[1]}</span>
                        <span className='edit-project_token_warn'>(*Bearer token - one per account)</span>
                    </span>
                </div>
            </div>
        </div >
    )
}