
import { useQuery } from "@tanstack/react-query";
import { getServices } from "../api/Api";

export default function useGetServices(token) {
    return useQuery({
        queryKey:['services'],
        queryFn:()=>getServices(token),
        enabled:!!token
    })
}
