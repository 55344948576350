import { useState, useContext, useEffect } from "react"
import { useParams, useLocation, useNavigate } from "react-router-dom"
import axios from "axios"
import axiosRetry from "axios-retry"
import WrongData from "../../components/wrongData/wrongData"
import "./WithdrawPage.css"
import { AppState } from "../../routes/Root"

export default function WithdrawPage() {
    axiosRetry(axios, { retries: 5 })
    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(AppState)
    const passedData = useLocation()
    const pageId = useParams()
    const paymentArrayInvoice = [['paypal', "USD"], ['paypal', "EUR"], ['tether', "USDT"], ['ethereum', "ETH"], ['tron', "TRX"]]
    const [pageState, setPageState] = useState({
        inputValue: 0,
        balance: passedData.state?.balance || "error",
        currency: passedData.state?.currency,
        activeButton: true
    })
    function backStory() {
        navigate("/total_balance", { replace: true })
    }
    function changeWithdrae(e) {
        // if (typeof parseInt(e.target.value, 10) === "number") {
        //     if (passedData.state.balance >= parseInt(e.target.value, 10)) {
        //         setPageState({ ...pageState, balance: passedData.state.balance - parseInt(e.target.value, 10), inputValue: parseInt(e.target.value, 10) })
        //     } else {
        //         setPageState({ ...pageState, balance: passedData.state.balance, inputValue: 0 })
        //     }
        // } else {
        //     setPageState({ ...pageState, balance: passedData.state.balance, inputValue: 0 })
        // }
        setPageState({ ...pageState, balance: passedData.state.balance, inputValue: e.target.value })
    }
    function timeOut() {
        setPageState({ ...pageState, activeButton: true })
        setTimeout(() => {
            navigate("/total_balance")
        }, 3000)
    }
    function redirect() {
        setPageState({ ...pageState, activeButton: false })
        let paymentMethod = paymentArrayInvoice.filter(element => element[1].toLocaleLowerCase() === pageState.currency)
        axios.post("https://api.sprite-ps.com/withdrawal",
            {
                balance_type: paymentMethod[0][0],
                currency: paymentMethod[0][1],
                amount: pageState.inputValue
            },
            {
                headers: {
                    Authorization: localStorage.getItem("TokenBearer")
                }
            }
        )
            .then((response) => {
                setPageState({ ...pageState, activeButton: true })
                context.dispatch({ type: "success_popup", payload: "Withdraw was successful." })
            }
            ).catch((error) => {
                if (error.status === 402) {
                    context.dispatch({ type: "error_popup", payload: error.response.data.message })
                    setPageState({ ...pageState, activeButton: true })
                } else if (error.status === 504) {
                    context.dispatch({ type: "error_popup", payload: "Server error" })
                    setPageState({ ...pageState, activeButton: true })
                } else {
                    console.log(error)
                    context.dispatch({ type: "error_popup", payload: "Select amount" })
                    setPageState({ ...pageState, activeButton: true })
                }
            })
    }
    // useEffect(() => {
    //     window.Telegram.WebApp.BackButton.onClick(backStory)
    // }, [location.pathname])
    return (
        <>
            {pageState.balance === "error" ? <WrongData /> :
                <div className="mainFraim block">
                    <div className="withdraw-header">
                        <h1>Withdraw Transaction</h1>
                    </div>
                    <div className="whithdraw-page-content styled-block">
                        <span> Balance :<br /> {pageState.balance} {passedData.state.walletName}</span>
                        {/* <div className="whithdraw-page-conten-data">
                            <p>Card entry fee: {passedData.state.fee_data.card_fee}%. <br />Deposit fee for <span className="wallet-value">{pageId.id}</span> wallet: {passedData.state.fee_data.crypto_fee}% + network commission.</p>
                        </div>
                        <hr /> */}
                        <div className="withdraw-controller">
                            <p>How many you want to <span style={{ whiteSpace: "nowrap" }}>withdraw?*</span></p>
                            <span>
                                <input className="invoice-input" placeholder="0" inputMode="numeric" onChange={(e) => changeWithdrae(e)} value={pageState.inputValue} />
                                <span className="withdraw-warning">*Withdraw are made Mon-Fri 10-18 Moscow time and may take up to 48 hours</span>
                            </span>
                            {pageState.activeButton ?
                                <button className="btn-done" onClick={() => redirect()}>Accept</button>
                                : <button className="btn-done"> Please wait</button>}
                        </div>
                    </div>
                </div>
            }
        </>
    )
}